import { FC, ReactNode } from 'react';

import { Footer } from 'Footer';
import { Header } from 'Header';

interface LayoutProps {
	children: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
	return (
		<div className="flex flex-col justify-between min-h-screen">
			<Header />
			<main>{children}</main>
			<Footer />
		</div>
	);
};
