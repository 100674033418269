import { SocialIcons } from 'SocialIcons';
import { ThemeToggle } from 'ThemeToggle';

const Footer = () => {
	return (
		<footer className="mx-auto md:mx-0 md:flex md:justify-between mt-8 md:mt-20 px-4 md:px-20 py-8 text-sm opacity-70">
			<div className="pb-8 md:pb-0">
				&copy; {new Date().getFullYear()} TradingSpreads
			</div>
			<SocialIcons
				profiles={[
					{
						name: 'Twitter',
						url: 'https://twitter.com/tradingspreads',
						icon: 'TWITTER',
					},
					{
						name: 'LinkedIn',
						url: 'https://www.linkedin.com/in/tradingspreads',
						icon: 'LINKEDIN',
					},
					{
						name: 'TikTok',
						url: 'https://tiktok.com/tradingspreads',
						icon: 'TIKTOK',
					},
					{
						name: 'YouTube',
						url: 'https://www.youtube.com/c/tradingspreads',
						icon: 'YOUTUBE',
					},
				]}
			/>
			<div className="hidden md:block pl-24">
				<ThemeToggle />
			</div>
		</footer>
	);
};

export { Footer };
