import { AnimatePresence } from 'framer-motion';
import { AppProps } from 'next/app';
import { ThemeProvider } from 'next-themes';
import { SWRConfig } from 'swr';
import '../styles/index.css';
import '../styles/search.css';
import { Layout } from 'Layout';

function MyApp({ Component, pageProps }: AppProps) {
	return (
		<ThemeProvider attribute="class" defaultTheme="dark">
			<SWRConfig
				value={{
					provider: () => new Map(),
					fetcher: (resource, init) =>
						fetch(resource, init).then((res) => res.json()),
				}}
			>
				<Layout>
					<AnimatePresence
						exitBeforeEnter
						initial={false}
						onExitComplete={() => window.scrollTo(0, 0)}
					>
						<Component {...pageProps} />
					</AnimatePresence>
				</Layout>
			</SWRConfig>
		</ThemeProvider>
	);
}

export default MyApp;
