import {
	AutocompleteOptions,
	AutocompleteState,
	createAutocomplete,
} from '@algolia/autocomplete-core';
import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia';
import { Hit } from '@algolia/client-search';
import algoliasearch from 'algoliasearch/lite';
import React from 'react';
import { parseAlgoliaHitHighlight } from '@algolia/autocomplete-preset-algolia';
import { createElement, Fragment } from 'react';
import Image from 'next/image';
import { Icon } from 'Icon';
import { useRouter } from 'next/router';

type HighlightHitParams<THit> = {
	hit: THit;
	attribute: keyof THit | string[];
	tagName?: string;
};

export function Highlight<THit>({
	hit,
	attribute,
	tagName = 'mark',
}: HighlightHitParams<THit>): JSX.Element {
	return createElement(
		Fragment,
		{},
		parseAlgoliaHitHighlight<THit>({ hit, attribute }).map(
			({ value, isHighlighted }, index) => {
				if (isHighlighted) {
					return createElement(tagName, { key: index }, value);
				}
				return value;
			}
		)
	);
}

const searchClient = algoliasearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? '',
	process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? ''
);

type AutocompleteItem = Hit<{
	image: string;
	name: string;
	symbol: string;
	objectID: string;
	url: string;
	path: string;
	type: string;
}>;

const Search = (props: Partial<AutocompleteOptions<AutocompleteItem>>) => {
	const [autocompleteState, setAutocompleteState] = React.useState<
		AutocompleteState<AutocompleteItem>
	>({
		collections: [],
		completion: null,
		context: {},
		isOpen: false,
		query: '',
		activeItemId: null,
		status: 'idle',
	});
	const autocomplete = React.useMemo(
		() =>
			createAutocomplete<
				AutocompleteItem,
				React.BaseSyntheticEvent,
				React.MouseEvent,
				React.KeyboardEvent
			>({
				onStateChange({ state }) {
					setAutocompleteState(state);
				},
				getSources() {
					return [
						{
							sourceId: 'products',
							getItems({ query }) {
								return getAlgoliaResults({
									searchClient,
									queries: [
										{
											indexName: 'ts',
											query,
											params: {
												hitsPerPage: 8,
											},
										},
									],
								});
							},
							getItemUrl({ item }) {
								return item.url;
							},
						},
					];
				},
				...props,
			}),
		[props]
	);
	const router = useRouter();
	const inputRef = React.useRef<HTMLInputElement>(null);
	const formRef = React.useRef<HTMLFormElement>(null);
	const panelRef = React.useRef<HTMLDivElement>(null);
	const { getEnvironmentProps } = autocomplete;
	const [searchOpen, setSearchOpen] = React.useState<boolean>(false);

	React.useEffect(() => {
		if (!formRef.current || !panelRef.current || !inputRef.current) {
			return undefined;
		}

		const { onTouchStart, onTouchMove } = getEnvironmentProps({
			formElement: formRef.current,
			inputElement: inputRef.current,
			panelElement: panelRef.current,
		});

		window.addEventListener('touchstart', onTouchStart);
		window.addEventListener('touchmove', onTouchMove);

		return () => {
			window.removeEventListener('touchstart', onTouchStart);
			window.removeEventListener('touchmove', onTouchMove);
		};
	}, [getEnvironmentProps, formRef, inputRef, panelRef]);

	React.useEffect(() => {
		const element = document.querySelector('#header-links');
		if (searchOpen) {
			element?.classList.add('hidden');
		}

		return () => {
			element?.classList.remove('hidden');
		};
	}, [searchOpen]);

	return (
		<>
			{!searchOpen ? (
				<button
					type="button"
					className="h-9 w-9 md:h-5 md:w-5 mr-8 md:mr-0 mt-0"
					onClick={() => setSearchOpen(true)}
				>
					<Icon icon="SEARCH2" />
				</button>
			) : (
				<div className="aa-Autocomplete" {...autocomplete.getRootProps({})}>
					<form
						ref={formRef}
						className="aa-Form"
						{...autocomplete.getFormProps({ inputElement: inputRef.current })}
					>
						<div className="aa-InputWrapperPrefix">
							<label className="aa-Label" {...autocomplete.getLabelProps({})}>
								<button
									className="aa-SubmitButton"
									type="submit"
									title="Submit"
								>
									<svg width="20" height="20" viewBox="0 0 20 20">
										<path
											d="M14.386 14.386l4.0877 4.0877-4.0877-4.0877c-2.9418 2.9419-7.7115 2.9419-10.6533 0-2.9419-2.9418-2.9419-7.7115 0-10.6533 2.9418-2.9419 7.7115-2.9419 10.6533 0 2.9419 2.9418 2.9419 7.7115 0 10.6533z"
											fill="none"
											fillRule="evenodd"
											stroke="currentColor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="1.4"
										/>
									</svg>
								</button>
							</label>
						</div>
						<div className="aa-InputWrapper">
							<input
								className="aa-Input"
								ref={inputRef}
								{...autocomplete.getInputProps({
									inputElement: inputRef.current,
								})}
							/>
						</div>
						<div className="aa-InputWrapperSuffix">
							<button
								className="aa-ClearButton"
								title="Clear"
								type="reset"
								onClick={() => setSearchOpen(false)}
							>
								<svg
									width="20"
									height="20"
									viewBox="0 0 20 20"
									fill="currentColor"
								>
									<path
										d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
										fillRule="evenodd"
										clipRule="evenodd"
									/>
								</svg>
							</button>
						</div>
					</form>

					{autocompleteState.isOpen && (
						<div
							ref={panelRef}
							className={[
								'aa-Panel',
								'aa-Panel--desktop',
								autocompleteState.status === 'stalled' && 'aa-Panel--stalled',
							]
								.filter(Boolean)
								.join(' ')}
							{...autocomplete.getPanelProps({})}
						>
							<div className="aa-PanelLayout aa-Panel--scrollable">
								{autocompleteState.collections.map((collection, index) => {
									const { source, items } = collection;
									return (
										<section key={`source-${index}`} className="aa-Source">
											{items.length > 0 && (
												<>
													<div className="aa-SourceHeader hidden">
														<span className="aa-SourceHeaderTitle"></span>
														<div className="aa-SourceHeaderLine"></div>
													</div>
													<ul
														className="aa-List"
														{...autocomplete.getListProps()}
													>
														{items.map((item) => {
															return (
																<li
																	key={item.objectID}
																	className="aa-Item"
																	{...autocomplete.getItemProps({
																		item,
																		source,
																	})}
																	onClick={() => {
																		router.push(item.path);
																		setSearchOpen(false);
																	}}
																>
																	<div className="aa-ItemWrapper">
																		<div className="aa-ItemContent">
																			<div className="aa-ItemIcon aa-ItemIcon--noBorder">
																				{item.image ? (
																					<Image
																						src={item.image}
																						width={20}
																						height={20}
																						className="rounded-full"
																					/>
																				) : (
																					<svg
																						viewBox="0 0 24 24"
																						fill="currentColor"
																					>
																						<path d="M16.041 15.856c-0.034 0.026-0.067 0.055-0.099 0.087s-0.060 0.064-0.087 0.099c-1.258 1.213-2.969 1.958-4.855 1.958-1.933 0-3.682-0.782-4.95-2.050s-2.050-3.017-2.050-4.95 0.782-3.682 2.050-4.95 3.017-2.050 4.95-2.050 3.682 0.782 4.95 2.050 2.050 3.017 2.050 4.95c0 1.886-0.745 3.597-1.959 4.856zM21.707 20.293l-3.675-3.675c1.231-1.54 1.968-3.493 1.968-5.618 0-2.485-1.008-4.736-2.636-6.364s-3.879-2.636-6.364-2.636-4.736 1.008-6.364 2.636-2.636 3.879-2.636 6.364 1.008 4.736 2.636 6.364 3.879 2.636 6.364 2.636c2.125 0 4.078-0.737 5.618-1.968l3.675 3.675c0.391 0.391 1.024 0.391 1.414 0s0.391-1.024 0-1.414z"></path>
																					</svg>
																				)}
																			</div>

																			<div className="aa-ItemContentBody">
																				<div className="aa-ItemContentTitle">
																					<Highlight
																						hit={item}
																						attribute="symbol"
																					/>
																				</div>
																				<div className="aa-ItemContentDescription">
																					<Highlight
																						hit={item}
																						attribute="name"
																					/>
																				</div>
																			</div>
																		</div>
																		<div className="aa-ItemActions">
																			<button
																				className="aa-ItemActionButton aa-DesktopOnly aa-ActiveOnly"
																				type="button"
																				title="Select"
																				style={{ pointerEvents: 'none' }}
																			>
																				<svg
																					fill="currentColor"
																					viewBox="0 0 24 24"
																				>
																					<path d="M18.984 6.984h2.016v6h-15.188l3.609 3.609-1.406 1.406-6-6 6-6 1.406 1.406-3.609 3.609h13.172v-4.031z" />
																				</svg>
																			</button>
																		</div>
																	</div>
																</li>
															);
														})}
													</ul>
												</>
											)}
										</section>
									);
								})}
							</div>
						</div>
					)}
				</div>
			)}
		</>
	);
};

export { Search };
