import Link from 'next/link';
import { motion } from 'framer-motion';
import { BurgerIcon } from 'BurgerIcon';
import { Icon } from 'Icon';
import { MobileMenu } from 'MobileMenu';
import { NavigationItem } from 'NavigationItem';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Search } from 'Search';

export const navItems = [
	{
		href: '/fees-calculator',
		title: 'Fees Calculator',
	},
	{
		href: '/compare-brokers',
		title: 'Brokers',
	},
	{
		href: '/crypto',
		title: 'Crypto',
	},
	{
		href: '/news',
		title: 'News',
	},
	{
		href: '/about',
		title: 'About',
	},
];

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { pathname } = useRouter();

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		}

		return () => {
			document.body.style.overflow = 'unset';
		};
	}, [isOpen]);

	useEffect(() => {
		setIsOpen(false);
	}, [pathname]);

	const navigationVariants = {
		hidden: { opacity: 0, y: -10 },
		visible: (custom: number) => ({
			opacity: 1,
			y: 0,
			transition: { delay: custom },
		}),
	};

	return (
		<>
			<header className="z-50 px-4 xl:px-20 py-8">
				<div className="flex justify-between items-center relative">
					<div
						id="header-links"
						className="flex md:flex items-center gap-4 lg:gap-12"
					>
						<Link href="/" passHref>
							<motion.a
								className="group relative transition whitespace-nowrap hover:transition duration-300 text-2xl font-bold"
								animate={{ opacity: 1, y: 0 }}
								initial={{ opacity: 0, y: -10 }}
							>
								<div className="flex items-center">
									<div className="w-12">
										<Icon icon="FAVICON" />
									</div>
									<span className="transition duration-300 opacity-90 group-hover:opacity-100">
										Trading
										<span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-start to-purple-stop">
											Spreads
										</span>
									</span>
								</div>
							</motion.a>
						</Link>
						<nav className="hidden md:block">
							<ul className="flex gap-4 lg:gap-8 text-lg">
								{navItems.map(({ href, title }, i) => (
									<NavigationItem
										href={href}
										title={title}
										key={href}
										variants={navigationVariants}
										initial="hidden"
										animate="visible"
										customDelay={(i + 1) * 0.1}
									/>
								))}
							</ul>
						</nav>
					</div>
					<button
						className="absolute z-50 top-2 right-4 md:hidden"
						onClick={() => setIsOpen((prev) => !prev)}
						aria-label="Menu"
					>
						<BurgerIcon isOpen={isOpen} />
					</button>

					<div className="mr-8 md:mr-0">
						<div className="flex">
							<Search
								placeholder="Search"
								openOnFocus={true}
								debug={false}
								autoFocus={true}
							/>
						</div>
					</div>
				</div>
			</header>
			<MobileMenu isOpen={isOpen} />
		</>
	);
};

export { Header };
